import React, {useState} from "react";
import {useHistory, Link} from 'react-router-dom'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {
    Paper, Grid, TextField, Button,
    InputAdornment,
    FormControlLabel, Checkbox,
    FormControl, InputLabel,
    FilledInput, FormHelperText,
    OutlinedInput,
    IconButton
} from "@material-ui/core";
import {
    MailOutline,
    Instagram,
    LinkedIn,
    GitHub
} from '@material-ui/icons'


import {signIn} from '../actions/auth'

const useStyles = makeStyles((theme) => ({
    root: {

    },

    loginForm: {
        background: '#F5FAFF',

        '& h1': {
            fontSize: 30,
            margin: '0 0 16px 0',
            textAlign: 'center'
        },

        '& p': {
            textAlign: 'left',
            margin: '0 0 30px 0',
            fontSize: 14,

            '&.Mui-error': {
                margin: '0 0 3px 0',
            }
        },

        '& p a': {
            color: 'black',
        }
    },

    formRow: {
        marginBottom: theme.spacing(2)
    },

    field: {
        width: '100%',
        boxSizing: 'border-box',
        color: theme.color.black,
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 14px) scale(1)',
            fontSize: 14,
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)'
            }
        },
        '& .MuiInputBase-root': {
            borderRadius: 0,
            borderColor: theme.color.black,
            '& input': {
                padding: 12,
                fontSize: 14
            }
        }
    },

    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    actionsContainer: {
        /*
                marginBottom: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
        */
        margin: '30px 0 5px 0',
        textAlign: 'right',
        '& a': {
            marginRight: 5,
            color: 'black'
        }
    },

    loginButton: {
        backgroundColor: 'black',
        color: '#ffffff',
        width: 140,
        height: 40,
        fontSize: 14
    },

    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit * 4
    }
}));


export default function ThankyouForm(props) {
    // const {onSingUp} = props

    const classes = useStyles();

    return (
        <div className={classes.loginForm}>
            <h1>Vielen Dank für Ihre Anmeldung!</h1>
            <p>
                Die Bestätigung erfolgt in Kürze per E-Mail!
            </p>
            <p>
                P.S. Sei Teil der LOKK-Community und unterstütze uns mit Deinem Feedback. Kontaktiere uns!
            </p>
            <div className={classes.formRow}>

            </div>
            <div className={classes.formRow}>

            </div>

            <div className={classes.actionsContainer}>
                <a href="mailto:info@lokk-platform.com" target="_blank"><MailOutline /></a>
                <a href="https://www.instagram.com/lokk_platform/" target="_blank"><Instagram /></a>
                <a href="https://www.linkedin.com/company/lokk-ug-haftungsbeschr%C3%A4nkt/" target="_blank"><LinkedIn /></a>
            </div>
        </div>
    )
}