import React, {useState} from "react";
import {useHistory, Link} from 'react-router-dom'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {
    Paper, Grid, TextField, Button,
    InputAdornment,
    FormControlLabel, Checkbox,
    FormControl, InputLabel,
    FilledInput, FormHelperText,
    OutlinedInput,
    IconButton
} from "@material-ui/core";
import {AccountCircle, Visibility, VisibilityOff} from '@material-ui/icons'


import {signIn, signUp} from '../actions/auth'

const useStyles = makeStyles((theme) => ({
    root: {

    },

    loginForm: {
        background: '#F5FAFF',

        '& h1': {
            fontSize: 30,
            margin: '0 0 16px 0',
            textAlign: 'center'
        },

        '& p': {
            textAlign: 'center',
            margin: '0 0 30px 0',
            fontSize: 14,

            '&.Mui-error': {
                margin: '0 0 3px 0',
            }
        },

        '& p a': {
            color: 'black',
        }
    },

    formRow: {
        marginBottom: theme.spacing(2)
    },

    field: {
        width: '100%',
        boxSizing: 'border-box',
        color: theme.color.black,
        '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 14px) scale(1)',
            fontSize: 14,
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)'
            }
        },
        '& .MuiInputBase-root': {
            borderRadius: 0,
            borderColor: theme.color.black,
            '& input': {
                padding: 12,
                fontSize: 14
            }
        }
    },

    agreeText:{
        '& a': {
            color: '#2A9BBE'
        }
    },

    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    actionsContainer: {
        /*
                marginBottom: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
        */
        margin: '30px 0 5px 0',
        textAlign: 'center'
    },

    signupButton: {
        backgroundColor: 'black',
        color: '#ffffff',
        width: 140,
        height: 40,
        fontSize: 14,
        cursor: 'pointer'
    },

    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit * 4
    }
}));


export default function SignupForm(props) {
    const {onLogin, onSignedUp, projectId, role} = props

    const classes = useStyles();
    const [errorState, setErrorState] = useState({
        errorMessage: null,
        errorFields: []
    })
    const clearError = () => setErrorState({errorMessage: null, errorFields: []})
    const [authData, setAuthData] = useState({
        name: '',
        email: '',
        password: '',
        project_id: projectId,
        role
    })

    const [isPending, setPending] = useState(false)
    const [showPass, setShowPass] = useState(false);
    const toggleShowPass = (e) => setShowPass((nowIsShowing) => !nowIsShowing)

    const handleSignUp = () => {

        if (isPending)
            return

        setPending(true)

        signUp(authData)
            .then((access_data) => {
                // window.location = 'https://app.lokk-platform.com'
                onSignedUp(authData)
                setPending(false)
            })
            .catch(err => {
                setErrorState({
                    errorFields: err.response.data.fields,
                    errorMessage: err.response.data.error
                })
                setPending(false)

                console.log({...err})
            })
    }

    return (
        <div className={classes.loginForm}>
            <form onSubmit={(e) => {
                e.preventDefault()
                handleSignUp()
            }}>
                <h1>Create Account</h1>
                <p>
                    Already registered? <Link
                    to='/'
                    onClick={() => {onLogin()}}
                >Login</Link>
                </p>
                <div className={classes.formRow}>
                    <TextField
                        id="name"
                        error={errorState.errorFields.indexOf('name') > -1}
                        helperText={errorState.errorFields.indexOf('name') > -1 ? errorState.errorMessage : null}
                        value={authData.name}
                        label="Name"
                        variant='outlined'
                        className={classes.field}
                        onChange={(e) => {
                            setAuthData((oldState) => ({
                                ...oldState,
                                name: e.target.value
                            }))
                            clearError();
                        }}
                    />
                </div>
                <div className={classes.formRow}>
                    <TextField
                        id="email"
                        error={errorState.errorFields.indexOf('email') > -1}
                        helperText={errorState.errorFields.indexOf('email') > -1 ? errorState.errorMessage : null}
                        value={authData.email}
                        label="Email"
                        variant='outlined'
                        className={classes.field}
                        onChange={(e) => {
                            setAuthData((oldState) => ({
                                ...oldState,
                                email: e.target.value
                            }))
                            clearError();
                        }}
                    />
                </div>
                <div className={classes.formRow}>
                    <FormControl className={classes.field} variant="filled">
                        <TextField
                            id="filled-adornment-password"
                            error={errorState.errorFields.indexOf('password') > -1}
                            helperText={errorState.errorFields.indexOf('password') > -1 ? errorState.errorMessage : null}
                            type={showPass ? 'text' : 'password'}
                            label='Password'
                            value={authData.password}
                            variant='outlined'
                            onChange={(e) => {
                                setAuthData((oldState) => ({
                                    ...oldState,
                                    password: e.target.value
                                }))
                                clearError();
                            }}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPass}
                                            onMouseDown={toggleShowPass}
                                            edge="end"
                                        >
                                            {showPass ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}


                        />

                    </FormControl>

                </div>

                <p className={classes.agreeText}>
                    By clicking on sign up, you agree to Lokk's <a href="https://lokk-platform.com/impressum/" target="_blank">Terms and Conditions of Use</a> and <a href="https://lokk-platform.com/datenschutz/" target="_blank">Lokk´s Privacy Policy.</a>
                </p>

                <div className={classes.actionsContainer}>
                    <button className={classes.signupButton}
                            disabled={isPending}
                            onClick={() => {handleSignUp()}}>
                        sign up
                    </button>
                </div>
            </form>
        </div>
    )
}