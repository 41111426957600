import { directAxios } from "../clients";
import Cookies from 'js-cookie'


export function signIn (creds) {
    return directAxios
        .post('/lokk/signin', creds).then((access_data) => {

            const data = access_data.data
            const expires =  (new Date((new Date()).getTime() + data.max_age * 1000))

            Cookies.remove('token');
            Cookies.set('token', data.access_token, {
                domain: '.lokk-platform.com',
                path: '/',
                expires: data.max_age,
            })

            // const tokenCookie = `token=${data.access_token};
            // Path=/;
            // Domain=.lokk-platform.com;
            // SameSite=Lax;
            // Max-Age=${data.max_age};
            // expires=${expires}`
            //
            // document.cookie = tokenCookie

            return data
        })
}

export function signUp (creds) {
    return directAxios
        .post('/lokk/signup', creds).then((access_data) => {

            const data = access_data.data
            const expires =  (new Date((new Date()).getTime() + data.max_age * 1000))

            // Cookies.remove('token');
            // Cookies.set('token', data.access_token, {
            //     domain: '.lokk-platform.com',
            //     path: '/',
            //     expires: data.max_age,
            // })


            console.log(`token=${data.access_token}; Path=/; Domain=.lokk-platform.com; SameSite=Lax; HttpOnly; Secure; Max-Age=${data.max_age}; expires=${expires}`)
            document.cookie = `token=${data.access_token}; Path=/; Domain=.lokk-platform.com; SameSite=Lax; HttpOnly; Secure; Max-Age=${data.max_age}; expires=${expires}`

            return data
        })
}


