import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import IndexPage from './pages/index'
import JoinPage from './pages/join'
import { lokkTheme } from './styles/theme'

// import './App.css';

function App() {
  return (
      <ThemeProvider theme={lokkTheme}>
        <CssBaseline />
        <Router>
          <Switch>
              <Route path="/" exact component={IndexPage} />
              <Route path="/join/:project_id" exact component={JoinPage} />
          </Switch>
        </Router>
      </ThemeProvider>
  );
}

export default App;
