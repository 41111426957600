import { createMuiTheme } from '@material-ui/core/styles';


const color = {
    background: '#29323C',
    black: '#394053',
    blackGray: '#35424E',
    darkGray: '#85929D',
    semiGray: '#B2BBC3',
    gray: '#C8CCD9',
    lightGray: '#D7DAE3',

}

const typography = {
    fontFamily: [
        'Montserrat',
        'sans-serif'
    ].join(', '),

    body: {
        background: color.background
    },

    h1: {
        fontSize: '18px'
    },

    h2: {
        fontSize: '16pt',
    },

    h3: {
        color: color.black,
        fontSize: '14pt',
        fontWeight: 'normal'
    },

    h4: {
        color: color.black,
        fontSize: '12pt',
        fontWeight: 'normal'
    },

    h5: {
        fontSize: '10pt',
    },

    h6: {
        fontSize: '8pt',
    },

    subtitle1: {

    },

    subtitle2: {

    },

    body1: {

    },

    body2: {

    }
}


const overrides = {

    MuiAccordionSummary: {
        content: {
            ...typography.h3
        }
    }
}

const common = {

    palette: {
        background: {
            default: color.background
        },
        text: {
            primary: color.black
        }
    },

}

export const lokkTheme = createMuiTheme({
    ...common,
    color,
    overrides,
    typography,
});