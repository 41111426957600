/**
 * Created by Stainwoortsel on 04.03.2017.
 */
import { appConfig } from '../config';
import axios from 'axios';

const directAxios = axios.create({
    baseURL: appConfig.api.auth,
    responseType: appConfig.api.type,
    // withCredentials: 'same-origin',
});

export default directAxios;
