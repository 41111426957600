import React, {useState} from "react";
import {useHistory, Link} from 'react-router-dom'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {
    Paper, Grid, TextField, Button,
    InputAdornment,
    FormControlLabel, Checkbox,
    FormControl, InputLabel,
    FilledInput, FormHelperText,
    OutlinedInput,
    IconButton
} from "@material-ui/core";
import {AccountCircle, Visibility, VisibilityOff} from '@material-ui/icons'

import Map from "../components/map";
import LoginForm from "../components/login"
import SignupForm from "../components/signup"
import ThankyouForm from '../components/thankyou'
import mapboxgl from 'mapbox-gl'

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const useStyles = makeStyles((theme) => ({
    root: {

    },
    formPlaceholder: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 360,
        minHeight: 200,
        zIndex: 100,
    },

    formPaper: {
        background: '#F5FAFF',
        borderRadius: 10,
        padding: '30px 50px',
        transition: 'all 0.3s ease-in-out'
    },

    thanksPaper: {
        background: '#F5FAFF',
        borderRadius: '50px 50px 50px 0',
        padding: 30,
        transition: 'all 0.3s ease-in-out'
    },

    formRow: {
        marginBottom: theme.spacing(2)
    },

    field: {
        width: '100%',
        boxSizing: 'border-box',
        color: theme.color.black,
        '& .MuiInputLabel-outlined': {
            transform: 'translate(translate(14px, 14px) scale(1))',
            fontSize: 14
        },
        '& .MuiInputBase-root': {
            borderRadius: 0,
            borderColor: theme.color.black,
            '& input': {
                padding: 12,
                fontSize: 14
            }
        }
    },

    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    actionsContainer: {
/*
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
*/
        margin: '30px 0 5px 0',
        textAlign: 'center'
    },

    loginButton: {
        backgroundColor: 'black',
        color: '#ffffff',
        width: 140,
        height: 40,
        fontSize: 14
    },

    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit * 4
    }
}));

const IndexPage = (props) => {
    const classes = useStyles();

    const [currentForm, setCurrentForm] = useState('login')

    const forms = {
        'login': <LoginForm
            onSingUp={() => {setCurrentForm('signup')}}
        />,
        'signup': <SignupForm
            onLogin={() => {setCurrentForm('login')}}
            onSignedUp={() => {setCurrentForm('thankyou')}}
        />,
        'thankyou': <ThankyouForm />
    }

    const paperClasses = {
        'login': classes.formPaper,
        'signup': classes.formPaper,
        'thankyou': classes.thanksPaper
    }

    return (
        <div className={classes.root}>
            <div className={classes.formPlaceholder}>
                <Paper className={paperClasses[currentForm]}>

                    {forms[currentForm]}

                </Paper>
            </div>

            <Map />
        </div>
    )
}

export default IndexPage;