import React, {useEffect, useState} from "react";

import StaticMap from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import {AmbientLight, PointLight, LightingEffect, } from '@deck.gl/core';
import DeckGL from '@deck.gl/react';
import {TransitionInterpolator, LinearInterpolator, FlyToInterpolator} from '@deck.gl/core'
import {GeoJsonLayer, PolygonLayer} from '@deck.gl/layers';
import {mapStyleBlack} from './style'


const MAPBOX_TOKEN = 'pk.eyJ1IjoibG9ra2kiLCJhIjoiY2tnZXRuMmNvMGt3NDJ5czR1bDZtcjFnMSJ9.qJ5YC4avQy4JK1_as3kllw'
const buildingPalette = ['#ED6262', '#F4A97F', '#EFE47F', '#D4DE98', '#9FD7B5', '#6BCEBC', '#79A9E1', '#A98BC7', '#BD68C5', '#E864B3', '#D35D87'];
const roadPalette = [
    '#9ED4F0', '#F0EC86',
    // '#F06E7B'
];

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

export function vector4toPolygon(geomVector, geomType) {

    const arrayGeom = geomVector // JSON.parse(geomVector.toString())

    if (geomType === 'Point') return null

    if (geomType === 'MultiPoint') return null

    if (geomType === 'Polygon') return arrayGeom

    return arrayGeom[0]
}

const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 1.0,
});

const pointLight1 = new PointLight({
    color: [255, 255, 255],
    intensity: 0.8,
    position: [11, 49.739968, 80000],
});

const pointLight2 = new PointLight({
    color: [255, 255, 255],
    intensity: 0.8,
    position: [12, 54.104682, 8000],
});

const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight1,
    pointLight2,
});

const material = {
    ambient: 0.5,
    diffuse: 0.5,
    shininess: 400,
    specularColor: [255, 255, 255]
};

const INITIAL_VIEW_STATE = {
    longitude: 11.329251760701794,
    latitude: 50.98123581574032,
    zoom: 14,
    minZoom: 5,
    maxZoom: 15,
    pitch: 40.5,
    bearing: -27,
};

function getTooltip({object}) {
    if (!object || !object?.position || object?.position?.length < 2) {
        return null;
    }
    const lat = object.position[1];
    const lng = object.position[0];
    const count = object.points.length;

    return `\
    latitude: ${Number.isFinite(lat) ? lat.toFixed(6) : ''}
    longitude: ${Number.isFinite(lng) ? lng.toFixed(6) : ''}
    ${count} Accidents`;
}

const transitionInterpolator = new LinearInterpolator(['bearing']);

export default function Map(props) {

    const [buildings, setBuildings] = useState([])
    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)

    const setAnimatedViewState = (oldBearing) => {
        const bearing = oldBearing + 180

        setViewState((currentViewState) =>({
            ...currentViewState,

            bearing,
            transitionDuration: 60000,
            transitionInterpolator,
            onTransitionEnd: () => {
                setAnimatedViewState(bearing)
            }
        }))
    }

    useEffect(() => {
        fetch('https://auth.lokk-platform.com/data/building.json').then((response) => {
            response.json().then(data => setBuildings(data))
        }).catch(() => {})
    }, [])

    const layers = [
        new GeoJsonLayer({
            id: 'roads-layer',
            data: 'https://auth.lokk-platform.com/data/road.json',
            // extruded: false,
            // getFillColor: computeFillColor,
            getLineColor: r => hexToRgb(roadPalette[Math.round(Math.random() * (roadPalette.length - 1))]),

            getPointRadius: 1,
            pointRadiusUnits: 'pixels',
            pointRadiusScale: 5,
            pointBillboard: true,

            getLineWidth: 2,
            lineWidthUnits: 'pixels',
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 20,

            stroked: true,
            filled: false,
            opacity: 0.03,

            // pickable: true,
            cursor: 'pointer',
            material: material,
        }),
        new PolygonLayer({
            id: 'building-layer',
            data: buildings,
            pickable: true,
            stroked: true,
            filled: true,
            wireframe: true,
            extruded: true,

            getPolygon: f => vector4toPolygon(f.polygon, f.type),
            getElevation: f => 20,
            getFillColor: f => hexToRgb(buildingPalette[Math.round((
                (1+ Math.sin(
                    vector4toPolygon(f.polygon, f.type)[0][0][0]*
                    vector4toPolygon(f.polygon, f.type)[0][0][1]
                ))/2
            ) * (buildingPalette.length - 1))]),
            getLineColor: [80, 80, 80],
            getLineWidth: 1,

            lineWidthUnits: 'pixels',
            lineWidthMinPixels: 1,
            // lineWidthMaxPixels: 20,
            // opacity: 0.7,
            // cursor: 'pointer',
            // material: material,
        })
    ]
    const mapStyle = mapStyleBlack //'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';

    return (
        <DeckGL
            layers={layers}
            effects={[lightingEffect]}
            initialViewState={viewState}
            controller={true}
            onLoad={() => {setAnimatedViewState(viewState.bearing)}}
            onViewStateChange ={(viewState) => {
                if (viewState.interactionState.isDragging) {
                    setViewState((currentViewState) => ({
                        ...currentViewState,
                        longitude: viewState.viewState.longitude,
                        latitude: viewState.viewState.latitude
                    }))
                }

                if (viewState.interactionState.isZooming) {
                    setViewState((currentViewState) => ({
                        ...currentViewState,
                        zoom: viewState.viewState.zoom,
                    }))
                }

                if (viewState.interactionState.isPanning) {
                    setViewState((currentViewState) => ({
                        ...currentViewState,
                        pitch: viewState.viewState.pitch,
                    }))
                }
            }}
            // getTooltip={getTooltip}
        >
            <StaticMap reuseMaps
                       mapboxAccessToken={MAPBOX_TOKEN}
                       mapStyle={mapStyle}
                       preventStyleDiffing={true}/>
        </DeckGL>
    );
}