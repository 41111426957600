/**
 * Created by stainwoortsel on 24.04.2020.
 */

const env = {
  base: "https://api.lokk-platform.com",
  auth: "/auth/v1",
};

export const appConfig = {
  api: {
    base: env.base,
    type: "json",
    auth: env.base + env.auth,
  },
  isProduction: process.env.NODE_ENV === "production",
  version: process.env.VERSION,
  defaultDomain: 'https://demo.lokk-platform.com'
};

export const ApiEndpoint = function (api) {
  this.apiName = api;
  this.apiBase = appConfig.api[this.apiName];
  this.endpoint = function (endpoint, params) {
    if (endpoint.length && endpoint[0] !== "/") {
      endpoint = "/" + endpoint;
    }
    if (params) {
      endpoint +=
        "?" +
        Object.keys(params)
          .map((k) => {
            return k + "=" + params[k];
          })
          .join("&");
    }

    return this.apiBase + endpoint;
  };
};

export const apiUrl = (api, endpoint) => {
  return appConfig.api[api] + endpoint;
};

export const webLink = (entity, slug, param) => {
  switch (entity) {
    case 'root':
      return '/';
    case "project":
      return "/" + entity + "/" + slug;
    default:
      return "/" + entity + "/" + slug;
  }
};

export const geocoderLink = (url, params) => {
  params = {...(params ? params : {}),
    'access_token': appConfig.mapbox.token
  }

  const paramString = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');

  return appConfig.mapbox.geocoding + url + '.json?' + paramString;
}
